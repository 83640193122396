import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import LightTheme from 'layouts/Light';
import NavbarMobileApp from 'components/Navbar-mobile-app/navbar-mobile-app';
import Intro6 from 'components/Intro6/intro6';
import Services7 from 'components/Services7/services7';
import Services8 from 'components/Services8/services8';
import Progress from 'components/Progress/progress';
import DownloadApp from 'components/Download-app/download-app';
import Footer2 from 'components/Footer2/footer2';

const MobileAppLight = ({ location }) => {
  const topRef = React.useRef(null);
  const navbarRef = React.useRef(null);
  const featuresRef = React.useRef(null);
  const guideRef = React.useRef(null);
  const downloadRef = React.useRef(null);

  useEffect(() => {
    if (location?.state && location.state?.ref) {
      scroller.scrollTo(location?.state?.ref, {
        smooth: true,
        containerId: location?.state?.ref,
      });
    }
  }, [location.state?.ref]);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add('nav-scroll');
    } else {
      navbar.classList.remove('nav-scroll');
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add('nav-scroll');
      } else {
        navbar.classList.remove('nav-scroll');
      }
    });
  }, [navbarRef]);

  return (
    <LightTheme mobileappstyle>
      <NavbarMobileApp nr={navbarRef} theme='themeL' />
      <Element id='top' ref={topRef} name='top'>
        <Intro6 />
      </Element>
      <Element id='features' ref={featuresRef} name='features'>
        <Services7 lightTheme />
      </Element>
      <Services8 />
      <Element id='guide' ref={guideRef} name='guide'>
        <Progress />
      </Element>
      <Element id='download' ref={downloadRef} name='download'>
        <DownloadApp />
      </Element>
      <Footer2 />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>UniService.Vn</title>
      <link
        href='https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'
        rel='stylesheet'
      />
    </>
  );
};

export default MobileAppLight;
