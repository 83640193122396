import React from 'react';
import { Link } from 'gatsby';
import { Link as LinkScroll } from 'react-scroll';

const Footer2 = () => {
  return (
    <footer className="app-footer" data-overlay-dark="0">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="item-clumn our md-mb50">
              <LinkScroll
                smooth={true}
                hashSpy={true}
                to="top"
                className="logo-brand mb-50"
              >
                <img src="/img/uni-app-logo.png" alt="UniService" />
              </LinkScroll>
              <p>Đặt phần ăn nhanh chóng cùng Uniservice</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="item-clumn links md-mb50">
              <h5 className="title">Resources</h5>
              <ul>
                <li>
                  <span className="icon pe-7s-angle-right"></span>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/vn/app/uni-service/id6449425647"
                    rel="noreferrer"
                  >
                    App Store
                  </a>
                </li>
                <li>
                  <span className="icon pe-7s-angle-right"></span>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=vn.uniservice.internalapp"
                    rel="noreferrer"
                  >
                    Google Play
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="item-clumn links sm-mb50">
              <h5 className="title">Links</h5>
              <ul>
                <li>
                  <span className="icon pe-7s-angle-right"></span>
                  <Link to={`/privacy-policy`}>Privacy Policy</Link>
                </li>
                <li>
                  <span className="icon pe-7s-angle-right"></span>
                  <Link to={`/terms`}>Terms of Use</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="item-clumn links">
              <h5 className="title mb-30">Contact</h5>
              <div className="info">
                <span>Email Address</span>
                <h6>
                  <a href="mailto:info@uniservice.vn">
                    info@uniservice.vn
                  </a>
                </h6>
              </div>
              <div className="social mt-30">
                <LinkScroll
                  smooth={true}
                  hashSpy={true}
                  to="top"
                  className="logo-brand mb-50"
                >
                  <i className="fab fa-facebook-f" />
                </LinkScroll>
                <LinkScroll
                  smooth={true}
                  hashSpy={true}
                  to="top"
                  className="logo-brand mb-50"
                >
                  <i className="fab fa-twitter" />
                </LinkScroll>
                <LinkScroll
                  smooth={true}
                  hashSpy={true}
                  to="top"
                  className="logo-brand mb-50"
                >
                  <i className="fab fa-instagram" />
                </LinkScroll>
                <LinkScroll
                  smooth={true}
                  hashSpy={true}
                  to="top"
                  className="logo-brand mb-50"
                >
                  <i className="fab fa-youtube" />
                </LinkScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <p>CÔNG TY CỔ PHẦN THỰC PHẨM KẾT TÌNH</p>
                <p>
                  Tầng 46, Tòa nhà Bitexco Financial Tower, Số 2 đường
                  Hải Triều, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí
                  Minh, Việt Nam
                </p>
                <p>
                  Chịu trách nhiệm quản lý nội dung: Nguyễn Trọng
                  Nghĩa - Điện thoại liên hệ: 0909 395459
                </p>
                <p>Mã số doanh nghiệp: 0313951571</p>
                <p>
                  @2023 - Bản quyền thuộc về CÔNG TY CỔ PHẦN THỰC PHẨM
                  KẾT TÌNH
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-blur"></div>
      <div className="circle-blur two"></div>
    </footer>
  );
};

export default Footer2;
