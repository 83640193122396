import React from 'react';

const Services8 = () => {
  return (
    <section className='app-services section-padding bg-gray'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-7 col-md-10'>
            <div className='s-head text-center mb-80'>
              <h6 className='stit mb-30'>
                <span className='left'></span> Tính Năng
                <span className='right'></span>
              </h6>
              <h2>Những Tính Năng Tuyệt Vời Của UniService</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 col-md-6'>
            <div className='item mb-30'>
              <div className='item-tit mb-15'>
                <div className='icon'>
                  <span className='pe-7s-vector'></span>
                </div>
                <div className='text-tit'>
                  <h5>Tiện Lợi</h5>
                </div>
              </div>

              <p>
                Thanh toán đơn giản và tiện lợi hơn nhờ phương thức quét mã QR,
                đồng thời ứng dụng hỗ trợ nạp tiền dễ dàng thông qua ví điện tử
                và thẻ ngân hàng.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='item mb-30'>
              <div className='item-tit mb-15'>
                <div className='icon'>
                  <span className='pe-7s-rocket'></span>
                </div>
                <div className='text-tit'>
                  <h5>Nhanh Chóng</h5>
                </div>
              </div>

              <p>
                Việc đặt phần ăn trở nên nhanh chóng và dễ dàng hơn bao giờ hết
                với giải pháp lựa chọn suất ăn trong ngày hoặc đặt món cho ngày
                hôm sau trên nền tảng ứng dụng trong mục Menu và Pre-Order.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='item mb-30'>
              <div className='item-tit mb-15'>
                <div className='icon'>
                  <span className='pe-7s-graph3'></span>
                </div>
                <div className='text-tit'>
                  <h5>Đa Dạng</h5>
                </div>
              </div>

              <p>
                Thực đơn đa dạng và phong phú với nhiều sự lựa chọn, giúp người
                dùng có thể tìm thấy các món ăn phù hợp với khẩu vị và nhu cầu
                của mình.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='item sm-mb50'>
              <div className='item-tit mb-15'>
                <div className='icon'>
                  <span className='pe-7s-anchor'></span>
                </div>
                <div className='text-tit'>
                  <h5>Khoa Học</h5>
                </div>
              </div>

              <p>
                Thực đơn được thiết kế khoa học, đảm bảo đầy đủ dinh dưỡng, tốt
                cho sức khỏe và được cập nhật liên tục với nhiều món ăn mới bởi
                các chuyên gia dinh dưỡng trong hệ thống.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='item sm-mb50'>
              <div className='item-tit mb-15'>
                <div className='icon'>
                  <span className='pe-7s-phone'></span>
                </div>
                <div className='text-tit'>
                  <h5>Thân Thiện</h5>
                </div>
              </div>

              <p>
                Với giao diện thân thiện, dễ sử dụng và thiết kế đẹp mắt, ứng
                dụng giúp người dùng có những trải nghiệm đặt phần ăn dễ dàng và
                thuận tiện hơn bao giờ hết.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='item'>
              <div className='item-tit mb-15'>
                <div className='icon'>
                  <span className='pe-7s-lock'></span>
                </div>
                <div className='text-tit'>
                  <h5>Bảo Mật</h5>
                </div>
              </div>

              <p>
                Bảo mật thông tin và cung cấp phương thức thanh toán an toàn,
                đảm bảo sự tin cậy và an tâm cho người dùng khi sử dụng dịch vụ
                đặt phần ăn.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services8;
