import React from 'react';

const Progress = () => {
  return (
    <section className='app-process section-padding'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-7 col-md-10'>
            <div className='s-head text-center mb-80'>
              <h6 className='stit mb-30'>
                <span className='left'></span> Hướng Dẫn
                <span className='right'></span>
              </h6>
              <h2>4 Bước Đặt Phần Ăn</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3'>
            <div className='item text-center md-mb50'>
              <span className='icon pe-7s-cloud-download'></span>
              <h5>Tải Ứng Dụng</h5>
              <span className='step-number'>BƯỚC 01</span>
              <p>
                Truy cập vào cửa hàng ứng dụng trên thiết bị, tìm kiếm tên ứng
                dụng " UniService " và nhấn nút tải xuống.
              </p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='item text-center md-mb50'>
              <span className='icon pe-7s-user'></span>
              <h5>Tạo Tài Khoản</h5>
              <span className='step-number'>BƯỚC 02</span>
              <p>
                Đăng ký tài khoản đơn giản bằng mã sinh viên hoặc email: nhập
                thông tin, xác thực tài khoản và hoàn thành.
              </p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='item text-center'>
              <span className='icon pe-7s-phone'></span>
              <h5>Nạp Tiền</h5>
              <span className='step-number'>BƯỚC 03</span>
              <p>
                Nạp tiền vào tài khoản bằng thẻ nội địa, thẻ quốc tế (Visa,
                master…) qua cổng thanh toán ONEFIN.
              </p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='item text-center'>
              <span className='icon pe-7s-phone'></span>
              <h5>Chọn & Thanh Toán</h5>
              <span className='step-number'>BƯỚC 04</span>
              <p>
                Chọn món ăn hoặc suất ăn cần đặt, quét mã QR xác nhận và nhận
                phần ăn tại các quầy tương ứng.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Progress;
