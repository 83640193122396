import React from 'react';

const Services7 = ({ lightTheme }) => {
  return (
    <section className='serv-block section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='serv-img md-mb50'>
              <img
                src={
                  lightTheme
                    ? '/img/mobile-app/app-img/light/s1.png'
                    : '/img/mobile-app/app-img/s1.png'
                }
                alt=''
              />
            </div>
          </div>
          <div className='col-lg-6 offset-lg-1 valign'>
            <div className='content'>
              <h6 className='stit mb-30'>
                <span className='left'></span> Nhanh Chóng & Tiện Lợi
              </h6>
              <h2 className='mb-30'>Đặt Món Ăn Nhanh Chóng Và Tiện Lợi</h2>
              <p>
                Với Uniservice, việc đặt món ăn trở nên đơn giản và tiện lợi hơn
                bao giờ hết. Chỉ cần vài thao tác đơn giản trên nền tảng ứng
                dụng App thông qua điện thoại, bạn có thể lựa chọn các món ăn
                yêu thích, phù hợp với khẩu vị và thanh toán một cách vô cùng
                nhanh chóng. Ứng dụng của chúng tôi sẽ giúp bạn tiết kiệm thời
                gian và mang lại trải nghiệm ăn uống tuyệt vời.
              </p>

              <div className='list-feat mt-40'>
                <ul>
                  <li className='mb-20'>
                    <i className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                      >
                        <path d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z' />
                      </svg>
                    </i>
                    Thao Tác Nhanh Chóng
                  </li>
                  <li className='mb-20'>
                    <i className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                      >
                        <path d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z' />
                      </svg>
                    </i>
                    Thực Đơn Đa Dạng
                  </li>
                  <li className='mb-20'>
                    <i className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                      >
                        <path d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z' />
                      </svg>
                    </i>
                    Thanh Toán Tiện Lợi
                  </li>
                  <li>
                    <i className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                      >
                        <path d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z' />
                      </svg>
                    </i>
                    Bảo Mật Thông Tin Tuyệt Đối
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-80'>
          <div className='col-lg-6 valign'>
            <div className='content md-mb50'>
              <h6 className='stit mb-30'>
                <span className='left'></span> Đa Dạng & Khoa Học
              </h6>
              <h2 className='mb-30'>
                Khám Phá Thực Đơn Đa Dạng Và Khoa Học Trên UniService
              </h2>
              <p>
                Bạn có thể khám phá thực đơn đa dạng và khoa học trên UniService
                ngay hôm nay. Với Ngân hàng thực đơn trên 600 món bao gồm các
                món chay, mặn và các món tráng miệng, bạn sẽ luôn tìm thấy thực
                đơn phù hợp với khẩu vị của mình hằng ngày. UniService cam kết
                cung cấp một trải nghiệm ăn uống đa dạng và khoa học giúp bạn
                duy trì một chế độ ăn uống lành mạnh, an toàn vệ sinh thực phẩm
                và đảm bảo tốt nhất cho sức khoẻ.
              </p>
            </div>
          </div>
          <div className='col-lg-5 offset-lg-1'>
            <div className='serv-img'>
              <img
                src={
                  lightTheme
                    ? '/img/mobile-app/app-img/light/s2.png'
                    : '/img/mobile-app/app-img/s2.png'
                }
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
      <div className='circle-blur'></div>
      <div className='circle-blur two'></div>
    </section>
  );
};

export default Services7;
